import React from 'react';
import { css } from '@emotion/core';
import fp from 'lodash/fp';
import firebase from 'firebase/app';
import getVideoId from 'get-video-id';

import { Button, Group } from '@myungsoo/components';
import { AdminPageBase, VideoThumbnail, DocumentList } from '@myungsoo/base/components';

import CategoryForm from './CategoryForm';
import VideoForm from './VideoForm';

const Header = ({ title, onAdd, ...restProps }) => (
  <div
    css={css`
      position: relative;
      margin-bottom: 1rem;
    `}
    {...restProps}
  >
    <h1>{title}</h1>
    <Button
      css={css`
        position: absolute;
        top: 0;
        right: 0;
      `}
      onClick={onAdd}
    >
      Add
    </Button>
  </div>
);

const Reel = ({ pageId, config }) => {
  const { DB_ROOT_PATH } = config;

  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const handleCategoryRemoved = React.useCallback(async (removedCategory) => {
    try {
      const db = firebase.firestore();

      const batch = db.batch();

      const articleCollection = await db
        .collection(`${DB_ROOT_PATH}/pages/${pageId}/articles`)
        .where('categoryIds', 'array-contains', removedCategory.id)
        .get();

      articleCollection.forEach((doc) => {
        batch.update(doc.ref, {
          categoryIds: firebase.firestore.FieldValue.arrayRemove(
            removedCategory.id,
          ),
        });
      });

      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  }, [DB_ROOT_PATH, pageId]);

  return (
    <AdminPageBase id={pageId} config={config} showContent={false} loading={loading}>
      <DocumentList
        collectionPath={`${DB_ROOT_PATH}/pages/${pageId}/categories`}
        renderHeader={({ onAdd }) => (
          <Header title="Categories" onAdd={onAdd} />
        )}
        renderDocument={({ doc, onClone, onEdit, onRemove }) => (
          <div
            css={css`
              position: relative;
              padding: 1rem 0;
              border-top: 1px solid rgba(255, 255, 255, 0.1);

              :hover {
                background: rgba(255, 255, 255, 0.05);
              }

              .buttons {
                display: none;
              }

              :hover .buttons {
                display: block;
              }
            `}
          >
            <h3
              css={css`
                margin: 0;
              `}
            >
              {doc.name}
            </h3>

            <Group
              className="buttons"
              css={css`
                position: absolute;
                top: 0;
                right: 0;
              `}
            >
              <Button
                css={css`
                  display: none;
                `}
                onClick={onClone}
              >
                Clone
              </Button>
              <Button onClick={onEdit}>Edit</Button>
              <Button onClick={onRemove}>Remove</Button>
            </Group>
          </div>
        )}
        renderDocumentForm={({ doc, onSave }) => (
          <CategoryForm doc={doc} onSave={onSave} />
        )}
        onLoaded={setCategories}
        onRemoved={handleCategoryRemoved}
        onLoadingChange={setLoading}
      />

      <hr />

      <DocumentList
        collectionPath={`${DB_ROOT_PATH}/pages/${pageId}/articles`}
        renderHeader={({ onAdd }) => <Header title="Videos" onAdd={onAdd} />}
        renderDocument={({ doc, onClone, onEdit, onRemove }) => {
          const video = getVideoId(doc.videoUrl);
          return (
            <article
              css={css`
                position: relative;

                display: flex;
                align-items: center;

                padding: 0.5rem 0;
                border-top: 1px solid rgba(255, 255, 255, 0.1);

                :hover {
                  background: rgba(255, 255, 255, 0.05);
                }

                .buttons {
                  display: none;
                }

                :hover .buttons {
                  display: block;
                }
              `}
            >
              <VideoThumbnail
                css={css`
                  width: 100px;
                  height: 40px;
                  margin-right: 0.5rem;
                `}
                videoId={video.id}
                videoService={video.service}
              />
              <div
                css={css`
                  flex: 1;
                `}
              >
                <h3
                  css={css`
                    margin: 0;
                  `}
                >
                  {doc.title}
                </h3>
                <ul
                  css={css`
                    margin: 0;
                    padding: 0;
                    list-style: 0;
                  `}
                >
                  {fp.flow(
                    fp.map((categoryId) => ({ id: categoryId })),
                    fp.intersectionBy('id')(categories),
                    fp.map((category) => (
                      <li
                        key={category.id}
                        css={css`
                          display: inline-block;
                          margin-right: 0.2rem;
                          font-size: 0.7rem;
                        `}
                      >
                        {category.name}
                      </li>
                    )),
                  )(doc.categoryIds)}
                </ul>
              </div>

              <Group
                className="buttons"
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                `}
              >
                <Button
                  css={css`
                    display: none;
                  `}
                  onClick={onClone}
                >
                  Clone
                </Button>
                <Button onClick={onEdit}>Edit</Button>
                <Button onClick={onRemove}>Remove</Button>
              </Group>
            </article>
          );
        }}
        renderDocumentForm={({ doc, onSave }) => (
          <VideoForm doc={doc} categories={categories} onSave={onSave} />
        )}
        onLoadingChange={setLoading}
      />
    </AdminPageBase>
  );
};

export default Reel;
