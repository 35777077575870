import React from 'react';

import { FormField, Input } from '@myungsoo/components';
import DocumentForm from '@myungsoo/base/components/DocumentForm';

const CategoryForm = ({
  doc: docProp = { name: '' },
  onSave,
  ...restProps
}) => {
  return (
    <DocumentForm
      doc={docProp}
      renderFields={({ doc, onUpdate }) => {
        return (
          <>
            <FormField label="Category Name">
              <Input
                value={doc.name}
                onChange={(event) => onUpdate({ name: event.target.value })}
              />
            </FormField>
          </>
        );
      }}
      onSave={onSave}
      {...restProps}
    />
  );
};

export default CategoryForm;
