import React from 'react';
import getVideoId from 'get-video-id';
import fp from 'lodash/fp';
import { css } from '@emotion/core';

import {
  Checkbox,
  FormField,
  Input,
  RichTextEditor,
} from '@myungsoo/components';

import { VideoThumbnail, DocumentForm } from '@myungsoo/base/components';

const VideoForm = ({
  doc: docProp = { title: '', videoUrl: '', description: '', categoryIds: [] },
  categories = [],
  onSave,
  ...restProps
}) => {
  const renderFields = React.useCallback(({ doc, onUpdate }) => {
    const video = getVideoId(doc.videoUrl);

    return (
      <>
        <FormField label="Title">
          <Input
            value={doc.title}
            onChange={(event) => onUpdate({ title: event.target.value })}
          />
        </FormField>

        <FormField label="Video URL">
          <Input
            value={doc.videoUrl}
            onChange={(event) => onUpdate({ videoUrl: event.target.value })}
          />
        </FormField>

        <FormField label="Video Thumbnail">
          <div
            css={css`
              width: 100px;
              height: 56px;
              border: 1px solid rgba(255, 255, 255, 0.1);
            `}
          >
            <VideoThumbnail
              css={css`
                width: 100%;
                height: 100%;
              `}
              videoId={video.id}
              videoService={video.service}
            />
          </div>
        </FormField>

        <FormField label="Video ID">
          <Input value={video.id || ''} disabled onChange={() => {}} />
        </FormField>

        <FormField label="Video Service">
          <Input value={video.service || ''} disabled onChange={() => {}} />
        </FormField>

        <FormField label="Categories">
          <div>
            {fp.map((category) => (
              <Checkbox
                key={category.id}
                css={css`
                  display: block;
                `}
                checked={fp.includes(category.id)(doc.categoryIds)}
                onChange={(event) => {
                  onUpdate({
                    categoryIds: event.target.checked
                      ? fp.union([category.id])(doc.categoryIds)
                      : fp.difference(doc.categoryIds)([category.id]),
                  });
                }}
              >
                {category.name}
              </Checkbox>
            ))(categories)}
          </div>
        </FormField>

        <FormField label="Description">
          <RichTextEditor
            css={css`
              max-height: 70vh;
            `}
            value={doc.description}
            onChange={(description) => onUpdate({ description })}
          />
        </FormField>
      </>
    );
  }, [categories]);

  return (
    <DocumentForm
      doc={docProp}
      renderFields={renderFields}
      onSave={onSave}
      {...restProps}
    />
  );
};

export default VideoForm;
